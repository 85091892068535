exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-process-tsx": () => import("./../../../src/pages/process.tsx" /* webpackChunkName: "component---src-pages-process-tsx" */),
  "component---src-pages-services-business-systems-tsx": () => import("./../../../src/pages/services/business-systems.tsx" /* webpackChunkName: "component---src-pages-services-business-systems-tsx" */),
  "component---src-pages-services-index-tsx": () => import("./../../../src/pages/services/index.tsx" /* webpackChunkName: "component---src-pages-services-index-tsx" */),
  "component---src-pages-services-legacy-reengineering-tsx": () => import("./../../../src/pages/services/legacy-reengineering.tsx" /* webpackChunkName: "component---src-pages-services-legacy-reengineering-tsx" */),
  "component---src-pages-services-mobile-apps-tsx": () => import("./../../../src/pages/services/mobile-apps.tsx" /* webpackChunkName: "component---src-pages-services-mobile-apps-tsx" */)
}

