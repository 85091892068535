import { graphql, useStaticQuery } from "gatsby"
import * as React from "react";
import { Helmet } from "react-helmet";

const Root = (props: React.PropsWithChildren<{}>) => {
    const { site, allFile } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        siteUrl
                        title
                        description
                    }
                }
                allFile(
                    limit: 1
                    filter: {
                        name: { eq: "favicon" }
                        ext: { eq: ".svg" }
                        sourceInstanceName: { eq: "images" }
                        relativeDirectory: { eq: "svg" }
                    }
                ) {
                    nodes {
                        publicURL
                    }
                }
            }
       `
    );

    return (
        <>
            <Helmet
                titleTemplate="%s | Eborsoft"
                defaultTitle={site.title}>
                <html lang="en" />
                <meta name="description" content={site.description} />
                <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
                <link
                    rel="icon"
                    // We get a url for this via graphql because we don't want to
                    // inline it, which webpack would do for us if we imported it
                    // directly
                    // Querying publicURL on a file node causes it to be copied
                    href={allFile.nodes[0].publicURL}
                    type="image/svg+xml"
                    sizes="any"
                />
            </Helmet>
            {props.children}
        </>
    );
};

export default Root;
